import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Nav from '../components/Nav'

export const ProjectPostTemplate = ({
  title,
  subtitle,
  body,
  tags,
  clientLogo,
}) => {
  // make a new comma splited array from single array
  const tag = tags[0].split(',')
  return (
    <div class="container w-full md:max-w-4xl mx-auto pt-20">
      <div class="w-full px-4 md:px-6 text-xl text-grey-darkest leading-normal">
        <hr class="border-b-2 border-grey-light mb-8 mx-4" />

        <div class="flex w-full items-center font-sans px-4 py-12">
          <div class="flex-1 px-2">
            <Img
              fluid={clientLogo.childImageSharp.fluid}
              className="w-12 h-12 mr-4"
            />
            <p class="text-base font-bold text-base md:text-xl leading-none mb-2">
              {title}
            </p>
            <p class="text-grey-dark text-xs md:text-base">
              {subtitle}
            </p>
          </div>
          <div class="justify-end">
            <div class="text-base md:text-sm text-grey flex flex-wrap px-4 py-6">
              {tag.map((tag, i) => (
                <span class="bg-transparent border border-grey hover:border-teal text-xs text-grey hover:text-teal my-2 font-bold py-2 px-4 rounded-full">
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
        <hr class="border-b-2 border-grey-light mb-8 mx-4" />

        <div className="markdown" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  )
}

// Export Default SinglePost for front-end
const ProjectPost = ({ data: { post, allPosts } }) => {
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <Nav />
      <ProjectPostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
      />
    </Layout>
  )
}

export default ProjectPost

export const pageQuery = graphql`
  ## Query for ProjectPost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ProjectPost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        tags
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
        clientLogo {
          childImageSharp {
            fluid(maxWidth: 500, quality: 98) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "project" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
